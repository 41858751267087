<template>
  <div class="shouyi">
    <!-- <title-top>收益</title-top> -->

    <div class="column titlebg">
      <img class="imgsize44 ml25 mt40 mb16" src="~images/fanhuiw.png" alt @click="onBack" />
      <div class="column align-center mt32 mb50 font24 f-FFFFFF">
        <span class="font60 fontwb mt60"></span>
        <span>累计收益</span>
      </div>
      <div class="row font24 f-FFFFFF mt35">
        <span class="flex1 row just-center" :class="{active:currentIndex == 0}" @click="onTab(0)">今日</span>
        <span class="flex1 row just-center" :class="{active:currentIndex == 1}" @click="onTab(1)">昨日</span>
        <span class="flex1 row just-center" :class="{active:currentIndex == 2}" @click="onTab(2)">本月</span>
        <span class="flex1 row just-center" :class="{active:currentIndex == 3}" @click="onTab(3)">上月</span>
      </div>
    </div>
    <div class="row align-center whitescreen h96 font28 f-1A1A1A mt20">
      <span class="ml25 mr50">收益(元)</span>
      <span>￥{{itemData.total_amounts}}</span>
    </div>
    <div class="row align-center h55 font28 f-333333 fontweight500">
      <span class="shuxian ml25 mr40"></span>
      <span>我的订单收益</span>
    </div>
    <div class="row align-center whitescreen h146 font28 f-1A1A1A mt20">
      <div class="row flex-center br8 border font32 fontwb">淘宝</div>
      <div class="flex1 column font24 f-333333 ml50">
        <div class="row align-center">
          <span class="mr40">预估收入</span>
          <span>￥{{itemData.tbk_amounts}}</span>
        </div>
        <div class="line mt25 mb25"></div>
        <div class="row align-center">
          <span class="mr40">订单数量</span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row align-center whitescreen h146 font28 f-1A1A1A">
      <div class="row flex-center br8 border font32 fontwb">京东</div>
      <div class="flex1 column font24 f-333333 ml50">
        <div class="row align-center">
          <span class="mr40">预估收入</span>
          <span>￥{{itemData.jingdong_amounts}}</span>
        </div>
        <div class="line mt25 mb25"></div>
        <div class="row align-center">
          <span class="mr40">订单数量</span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row align-center whitescreen h146 font28 f-1A1A1A">
      <div class="row flex-center br8 border font32 fontwb">拼多多</div>
      <div class="flex1 column font24 f-333333 ml50">
        <div class="row align-center">
          <span class="mr40">预估收入</span>
          <span>￥{{itemData.pdd_amounts}}</span>
        </div>
        <div class="line mt25 mb25"></div>
        <div class="row align-center">
          <span class="mr40">订单数量</span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row align-center h55 font28 f-333333 fontweight500">
      <span class="shuxian ml25 mr40"></span>
      <span>粉丝的订单收益</span>
    </div>
    <div class="row align-center whitescreen h146 font28 f-1A1A1A mt20">
      <div class="row flex-center br8 border font32 fontwb" style="border-color: #EA6061;">淘宝</div>
      <div class="flex1 column font24 f-333333 ml50">
        <div class="row align-center">
          <span class="mr40">预估收入</span>
          <span>￥{{itemData.downlines_tbk_amounts}}</span>
        </div>
        <div class="line mt25 mb25"></div>
        <div class="row align-center">
          <span class="mr40">订单数量</span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row align-center whitescreen h146 font28 f-1A1A1A">
      <div class="row flex-center br8 border font32 fontwb" style="border-color: #EA6061;">京东</div>
      <div class="flex1 column font24 f-333333 ml50">
        <div class="row align-center">
          <span class="mr40">预估收入</span>
          <span>￥{{itemData.downlines_jingdong_amounts}}</span>
        </div>
        <div class="line mt25 mb25"></div>
        <div class="row align-center">
          <span class="mr40">订单数量</span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row align-center whitescreen h146 font28 f-1A1A1A">
      <div class="row flex-center br8 border font32 fontwb" style="border-color: #EA6061;">拼多多</div>
      <div class="flex1 column font24 f-333333 ml50">
        <div class="row align-center">
          <span class="mr40">预估收入</span>
          <span>￥{{itemData.downlines_pdd_amounts}}</span>
        </div>
        <div class="line mt25 mb25"></div>
        <div class="row align-center">
          <span class="mr40">订单数量</span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {
  calculate_promotions,
  calculate_promotions_detail
} from "network/igou";
export default {
  name: "ShouYi",
  data() {
    return {
      currentIndex: 0,
      itemData: {}
    };
  },
  components: {
    TitleTop
  },
  mounted() {
    this.get_calculate_promotions_detail();
  },
  methods: {
    onTab(index) {
      if (this.currentIndex == index) {
        return;
      }
      this.currentIndex = index;
      this.get_calculate_promotions_detail();
    },
    /*
      get_calculate_promotions(){
          calculate_promotions().then(res=>{
            this.res = res;
          })
      },
      */
    get_calculate_promotions_detail() {
      let scope = "today";
      if (this.currentIndex == 1) {
        scope = "yesterday";
      } else if (this.currentIndex == 2) {
        scope = "current_month";
      } else if (this.currentIndex == 3) {
        scope = "last_month";
      }
      calculate_promotions_detail(scope).then(res => {
        this.itemData = res.data;
      });
    },
    onBack() {
      this.$router.back();
    }
  }
};
</script>
<style  scoped>
.active {
  font-size: 0.373333rem;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}
.titlebg {
  width: 100%;
  height: 4.906667rem;
  background: url(~images/zhezbg.png) no-repeat;
  background-size: 100% 4.906667rem;
}
.underline {
  height: 0.026667rem;
  width: 0.64rem;
  background-color: #ffffff;
}
.shuxian {
  width: 0.026667rem;
  height: 0.426667rem;
  background-color: #f62341;
}
.border {
  width: 2.133333rem;
  height: 1.946667rem;
  border: 0.026667rem solid #cf964b;
}
</style>
